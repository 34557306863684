.formColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.textHeader {
  margin-bottom: 10px;
  line-height: 20px;
  text-align: left;
  margin-top: 0px;
}

.formTextField {
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  font-family: 'GothamRounded';
}

.wInput {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'ChampagneLimousines';
  font-size: var(--font-size-sm);
  font-weight: 600;
  padding-left: 5px;
}
